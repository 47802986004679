.cardContainer {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.cardDisabled {
    position: absolute;
    width: 320px;
    height: 200px;
    background: rgba(206, 206, 206, 0.92);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardDiagonalText {
    transform: rotate(340deg);
    font-size: 42px;
    font-weight: 600;
    color: #d6d6d6;
    text-shadow: 4px 2px #939393;
    text-align: center;
}

    .cardRowContainer {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .cardHeader {
        display: flex;
        justify-content: space-between;
    }

    .textColumn {
        display: flex;
        flex-direction: column;
    }