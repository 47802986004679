.footerContainer {
  display: flex;
  height: 130px;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin-top: auto;
}

.logo {
  margin-right: 20;
  width: 90px;
  height: auto;
  fill: white;
}

.link {
  margin-bottom: 8px;
}

.link:last-of-type {
  margin-bottom: 0;
}