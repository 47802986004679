.Banner {
  height: 400px;
  position: relative;
}

.Media {
  background-color: white;
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: 300ms;
  cursor: pointer;
}

.Media:hover {
  filter: brightness(115%);
}

.BannerGrid {
  height: 100%;
  position: relative;
}

.DiscountContainer {
  width: 20rem;
  height: 20rem;
  border-radius: 160px;
  background-image: url(../../../background/bgRedPattern.png);
}
