.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.logo {
  margin-right: 20;
  width: 70px;
  height: 70px;
  fill: white;
}

.circle {
  position: absolute;
  top: -60rem;
  right: -9.8rem;
  z-index: -1;
  width: 80rem;
  height: 80rem;
  border-radius: 50%;
  text-align: center;
  background-image: url(../../background/bgRedPattern.png);
}

.cards {
  position: relative;
  top: 100%;
  transform: translateY(-50%);
}