.ornament-pattern-background {
  background-image: url(./bgGrayPattern.png);
}

.ornament-pattern-background-red {
  background-image: url(./bgRedPattern.png);
}

.ornament-pattern-light-red {
  background-image: url(./ornamentPatternLightRed.svg);
  background-size: 45px;
}

.ornament-pattern-red {
  background-image: url(./ornamentPatternRed.svg);
  background-size: 45px;
}

.ornament-pattern-light-green {
  background-image: url(./ornamentPatternLightGreen.svg);
  background-size: 45px;
}

.ornament-pattern-green {
  background-image: url(./ornamentPatternGreen.svg);
  background-size: 45px;
}

@media screen and (min-width: 1200px) {
  .ornament-pattern-gradient-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -3;
    opacity: 0.5;
    background-image: url(./bgGrayLine.png);
    background-size: 6000px;
    background-position: center;
  }
}
