html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

body {
  margin: 0;
  padding: 0 !important;
  font-family: 'DINRoundPro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > div:nth-of-type(1) {
  position: relative;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
  cursor: pointer;
}

/* MUI override */

.MuiButton-root.MuiButton-contained {
  padding: 0.6rem 1.4rem;
  border-radius: 8px;
}

.MuiButton-root.MuiButton-outlined.inverted {
  border-color: #fff;
  color: #fff;
}

.MuiButton-root.MuiButton-outlined.inverted:hover {
  border-color: #fff;
  background-color: #fff;
  color: #720023;
}

.Header-Footer-Nav-Link {
  color: #fff;
  display: block;
  word-wrap: break-word;
  max-width: 100px;
  text-align: center;
}

.Header-Footer-Nav-Link:hover {
  text-decoration: underline;
}

p {
  margin: 0;
  font-size: 1rem;
}

h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
}

.leaflet-container {
  height: 100%;
}

.hide-children > div {
  visibility: hidden;
}
