.gridContainer {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.inner {
  cursor: pointer;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.gridItemImg {
  width: 100%;
  height: 15em;
  object-fit: cover;
  object-position: 50% 50%;
}

@media only screen and (max-width: 48em) {
  /* 768px */
  .gridContainer {
    grid-template-columns: 1fr;
  }
}
